var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("j-vxe-table", {
    ref: "vTable",
    staticStyle: { "margin-top": "8px" },
    attrs: {
      toolbar: "",
      "row-number": "",
      "row-selection": "",
      "drag-sort": "",
      "keep-source": "",
      height: 580,
      loading: _vm.loading,
      dataSource: _vm.dataSource,
      columns: _vm.columns,
    },
    on: { valueChange: _vm.handleValueChange },
    scopedSlots: _vm._u([
      {
        key: "toolbarSuffix",
        fn: function () {
          return [
            _c("a-button", { on: { click: _vm.handleTableCheck } }, [
              _vm._v("表单验证"),
            ]),
            _c(
              "a-tooltip",
              {
                attrs: {
                  placement: "top",
                  title: "获取值，忽略表单验证",
                  autoAdjustOverflow: true,
                },
              },
              [
                _c("a-button", { on: { click: _vm.handleTableGet } }, [
                  _vm._v("获取值"),
                ]),
              ],
              1
            ),
            _c(
              "a-tooltip",
              {
                attrs: {
                  placement: "top",
                  title: "模拟加载1000条数据",
                  autoAdjustOverflow: true,
                },
              },
              [
                _c("a-button", { on: { click: _vm.handleTableSet } }, [
                  _vm._v("设置值"),
                ]),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "action",
        fn: function (props) {
          return [
            _c(
              "a",
              {
                on: {
                  click: function ($event) {
                    return _vm.handleCK(props)
                  },
                },
              },
              [_vm._v("查看")]
            ),
            _c("a-divider", { attrs: { type: "vertical" } }),
            _c(
              "a-popconfirm",
              {
                attrs: { title: "确定删除吗？" },
                on: {
                  confirm: function ($event) {
                    return _vm.handleDL(props)
                  },
                },
              },
              [_c("a", [_vm._v("删除")])]
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }